import { Socnet } from '@/types';
import classNames from 'classnames';
import { useSetSelectedServiceValueFormState } from '@/atoms/form/selected-service-value-form-state';
import { useIsAppNavOpenedValue, useSetOpenedPopupsState } from '@/atoms/opened-popups';
import { FEEDBACK_POPUP_NAME } from '../FeedbackPopup/FeedbackPopup';
import Button from '@/components/shared/Button';
import PhoneButton from '../PhoneButton';
import { YD_NEW_ID } from '@/metrics/ym';
import { useSetFeedbackPopupDataNameState } from '../FeedbackPopup/use-feedback-popup-data-name-atom';

const CallbackAndPhoneButtons = ({ phone, socnets }: { phone: string; socnets: Socnet[] }) => {
    const { openPopup } = useSetOpenedPopupsState();
    const isAppNavPopupOpened = useIsAppNavOpenedValue();
    const setFeedbackPopupDataName = useSetFeedbackPopupDataNameState();

    const setServiceValueFormState = useSetSelectedServiceValueFormState();
    return (
        <div className="callback-and-phone-buttons">
            <Button
                variant="primary"
                className={classNames('app-nav-callback-button app-nav-callback-button--touch', {
                    'app-nav--burger--opened': isAppNavPopupOpened,
                })}
                onClick={() => {
                    setServiceValueFormState('под заказ');
                    setFeedbackPopupDataName('order');
                    openPopup(FEEDBACK_POPUP_NAME);
                    window.ym?.(YD_NEW_ID, 'reachGoal', 'click_on_request');
                }}
            >
                под заказ
            </Button>
            <PhoneButton socnets={socnets} phone={phone} />
        </div>
    );
};

export default CallbackAndPhoneButtons;
